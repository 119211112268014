.navbar {
    width: 100%;
    height: 70px;
    background-color: #3bb19b;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar h1 {
    color: white;
    font-size: 25px;
    margin-left: 20px;
}

.white_btn {
    border: none;
    outline: none;
    padding: 12px 0;
    background-color: white;
    border-radius: 20px;
    width: 120px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    margin-right: 20px;
}

.search_form {
    @media (max-width: 768px) {
        display: none;
    }
}

.Card.Img {
    width: 10%;
}

.main_container1 {
    padding: 0px;
    margin: 0px;
}