.signup_container {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup_form_container {
    width: 900px;
    height: 500px;
    display: flex;
    border-radius: 10px;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5e863;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.left h1 {
    margin-top: 0;
    color: #120a63;
    font-size: 35px;
    align-self: center;
}

.right {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form_container h1 {
    font-size: 40px;
    margin-top: 0;
}

.input {
    outline: none;
    border: none;
    width: 370px;
    padding: 15px;
    border-radius: 10px;
    background-color: #edf5f3;
    margin: 5px 0;
    font-size: 14px;
}

.error_msg {
    width: 370px;
    padding: 15px;
    margin: 5px 0;
    font-size: 14px;
    background-color: #f34646;
    color: white;
    border-radius: 5px;
    text-align: center;
}

.white_btn,
.green_btn {
    border: none;
    outline: none;
    padding: 12px 0;
    background-color: white;
    border-radius: 20px;
    width: 180px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}

.green_btn {
    background-color: #f5e863;
    color: #120a63;
    margin: 10px;
}

.white_btn:hover {
    background-color: #120a63;
    color: white;
}

.green_btn:hover {
    background-color: #120a63;
    color: white;
}